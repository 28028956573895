<template>
  <div>
    <UIExit label="Exit App Documentation" :exit-url="defaultRedirect" />
    <TitleWrapper
      :filter-option="false"
      title="APP_DOCUMENT"
      tooltip-title="APP_DOCUMENT_TOOLTIP"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div>
      <NoRecordFound text="COMING SOON" />
    </div>
  </div>
</template>

<script>
import UIExit from '@/src/components/UiElements/UIExit.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import ROUTE from '@/src/constants/route-constants.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'

export default {
  name: 'AppDocumentation',
  components: {
    TitleWrapper,
    UIExit,
    NoRecordFound,
  },
  data() {
    return { defaultRedirect: ROUTE.QUICK_ACTIONS.name }
  },
  methods: {},
}
</script>
