<template>
  <div class="flex items-center rounded-md mb-2 mt-1.5">
    <div class="my-1 flex gap-1.5 items-center mr-2 cursor-pointer" @click="exitMode()">
      <div>
        <div
          class="h-6 w-6 bg-bg-success-light flex justify-center items-center cursor-pointer rounded-md"
        >
          <icon
            class="icon transform rotate-180"
            icon="signOut"
            color="primary-purple-600"
            height="10"
            width="13"
          />
        </div>
      </div>
      <div class="font-rubik text-sm text-primary-purple-600 exit-student-mode">{{ label }}</div>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'

export default {
  components: {
    icon,
  },
  props: {
    exitUrl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Exit',
    },
  },
  data() {
    return {
      redirectTo: localStorage.getItem('redirectTo'),
    }
  },
  methods: {
    exitMode() {
      let redirectRoute = this.exitUrl || this.redirectTo
      this.$router.push({ name: redirectRoute })
    },
  },
}
</script>
